@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes typing {
  from {
    content: " . ";
  }
  33% {
    content: " . . ";
  }
  66% {
    content: " . . . ";
  }
  to {
    content: " . ";
  }
}

.typing-dots::after {
  content: " . ";
  animation: typing 1s infinite;
}
